<template>
    <!-- 客户管理》同步状态管理 -->
    <div id="app_user_price_search">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="add()" size="mini">
                    增加
                </el-button>
            </div>
            <div class="head_top_title">同步状态管理</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">


            <el-row style="padding:10px 0;">
                <el-input  v-model="searchID" placeholder="检索状态" prefix-icon="el-icon-search" size="small"
                style="width:200px;margin-left:20px;"></el-input>
                <el-button type="primary"  plain size="mini" style="margin-left:20px;" @click="getData()">查询</el-button>
            </el-row>

            <el-row style="overflow: auto;" :style="{height: ((content_heigth-pagination_height)+'px')}">
                
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini" >
                    <el-table-column  prop="state_true" label="直接同步的状态" min-width="160"  align="center"> </el-table-column>
                    <el-table-column  prop="note" label="备注" min-width="160"  align="center"> </el-table-column>
                    <el-table-column  prop="login" label="添加的运营" min-width="160"  align="center"> </el-table-column>
                    <el-table-column  prop="createtime" label="日期" min-width="160"  align="center"> </el-table-column>

                    <el-table-column   label="操作"  min-width="100" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" style="color:#f56c6c"  @click="del(scope.row.state_true)">删除</el-button>
                        </template>
                    </el-table-column>

                </el-table>
            
            </el-row>
            <el-row id="pagination_id" style="padding:10px 0 0 0;text-align:center;line-height:26px;">
                <!-- 分页  layout="total, sizes, prev, pager, next, jumper"   small-->
                <el-pagination background  
                    layout="total, prev, pager, next,jumper"
                    :total="tableData_total"
                    small
                    :pager-count="5"
                    @size-change="pageSizeChange"
                    @current-change="pageCurrentChange"
                    :page-sizes="[5,10,15, 20, 30, 40]"
                     :page-size="pageSize"
                     style="white-space: normal !important;">
                </el-pagination>
            </el-row>

            
            

        </el-row>

        <!-- 同步状态增加组件 -->
        <SyncStateAdd :prop_change_i="prop_sync_state_add_i"
            @result="addResult"
        ></SyncStateAdd>

    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import SyncStateAdd from './sync_state_add.vue';//组件 客户公司增加或修改

//import axios from "axios";
export default {
    components: {
        SyncStateAdd
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            searchID:"",//搜索的id

            indexPage:1,//分页 第几页
            pageSize:10,//分页 一页的数量值
            tableData:[],//数据
            tableData_total:0,//表格数据总量
            pagination_height:40,//分页的高度

            //同步状态增加
            prop_sync_state_add_i:0,//同步状态增加  组件 弹出框是否可见

           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        // this.searchID = this.$options.data().searchID;//单个恢复初始化
        // this.indexPage = this.$options.data().indexPage;//单个恢复初始化
        // this.pageSize = this.$options.data().pageSize;//单个恢复初始化
        // this.tableData = this.$options.data().tableData;//单个恢复初始化
        // this.tableData_total = this.$options.data().tableData_total;//单个恢复初始化

        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));

        //初始化设置
        this.initSetUp();
        
        //查询
        this.getData();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 130);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);


        },

        //查询
        getData(){
            //请求接口
            API.UserServlet({
                param: "syncStateList",
                indexPage:this.indexPage,
                pageSize:this.pageSize,
                search_v:this.searchID,
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;
                    this.tableData_total = res.totalNum;

                    this.$nextTick(function(){
                        //获取分页的高度
                        this.pagination_height = document.getElementById('pagination_id').clientHeight;
                    });
                } 
            });
        },

        //分页大小发生改变调用
        pageSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val;
            //查询-- 请求接口
            this.getData();
        },
        //分页 当前页 发生改变调用
        pageCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.indexPage = val;
            //查询-- 请求接口
            this.getData();
        },

        
        //增加
        add(){
            console.log("增加");
            this.prop_sync_state_add_i ++;//客户重发策略增加或修改  组件 弹出框是否可见
        },
        //客户增加组件 结果回调方法
        addResult(){
            //查询-- 请求接口
            this.getData();
        },
        //删除
        del(state_true){
            this.$confirm('此操作将永久删除该同步状态, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                    //请求接口
                    API.UserServlet({
                        param: "syncStateDel",
                        state_true:state_true,
                    }).then((res) => {
                        if (res.recode === 0) {
                            this.$message({showClose: true,message: '恭喜你，同步状态删除成功', type: 'success'});
                            //重新加载数据
                            this.getData();
                        }
                    });
            }).catch(() => {//已取消删除
            });
        },
        
        //返回
        goBack() {
            API.router_to("/user_index");
        }
    }
};

</script>

<style scoped>

</style>