<template>
<div>
    <!-- 同步状态 增加 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" destroy-on-close :show-close="false" width="98%" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>增加同步状态</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" >
                <el-form  :model="form_data" label-width="80px" style="margin-bottom: 12px;">
                    <el-form-item label="状态">
                        <el-input type="textarea"  autosize v-model="form_data.state_trues" placeholder="请输入状态，多个使用回车或逗号隔开" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input v-model="form_data.note" placeholder="选填" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>

            </el-row>

        </el-row>


    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度


            form_data:{
                state_trues:"",//多个使用回车或逗号隔开
                note:"",//备注，选填
            }
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        //初始化设置
        this.initSetUp();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },

        //提交
        onSubmit(){
            console.log("提交");

            if(API.isEmtry(this.form_data.state_trues)){
                this.$message.error("请填写状态");
                return;
            }

            var submit_obj = {};
            submit_obj.param = "syncStateAdd";
            submit_obj.state_trues = this.form_data.state_trues;//	状态 必填 多个使用回车或逗号隔开
            submit_obj.note = this.form_data.note;//
            
            API.UserServlet(submit_obj).then((res) => {
                if (res.recode === 0) {
                    this.$alert(res.text).then(() => {
                        //关闭
                        this.back();
                        //回调
                        this.$emit("result",'');
                    }).catch(() => {
                        console.log("点击关闭按钮了");
                    });
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>